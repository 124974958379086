import { useMemo, useRef, useEffect, useState } from 'react'
import injectSheet from 'react-jss'
import clsx from 'clsx'
import style from './style'

function LangNav({
  className,
  classes,
  hide,
  translations,
  languages,
}) {
  const activeLang = useMemo(() => window.location.pathname.split('/')[1], [])
  const [visible, setVisible] = useState(false)
  const $button = useRef(null)

  const handleClick = () => {
    setVisible(!visible)
  }

  const handleOutsideClick = (event) => {
    if ($button.current && !$button.current.contains(event.target)) setVisible(false)
  }

  useEffect(() => {
    if (visible) {
      document.addEventListener('click', handleOutsideClick)
    } else {
      document.removeEventListener('click', handleOutsideClick)
    }
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [visible])

  return (
    <div className={clsx({
      [classes.root]: true,
      [classes.hide]: hide,
      [className]: true,
      'lang-nav': true,
    })}
    >
      <div
        className={clsx({
          [classes.item]: true,
          [classes.itemVisible]: visible,
          'lang-active': true,
        })}
      >
        <button
          ref={$button}
          onClick={handleClick}
          className={classes.parentItem}
        >
          <span>{activeLang}</span>
          <svg viewBox="0 0 224 390" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.012,386.804l-0.002,0l-26.148,-26.163l165.846,-165.811l-165.753,-165.819l0,-0.001l26.164,-26.149l191.902,191.981l-192.009,191.962ZM29.016,361.347l166.55,-166.509l-166.453,-166.522l-0.701,0.701l165.751,165.817l-165.848,165.812l0.701,0.701Z" />
          </svg>
        </button>
      </div>
      <ul className={clsx({
        [classes.popup]: true,
        [classes.visible]: visible,
      })}
      >
        {translations.length > 0 ? translations
          .filter((translation) => !translation?.locale?.includes(activeLang))
          .map((translation, index) => {
            return (
              <li
                key={index.toString()} // eslint-disable-line react/no-array-index-key
                className={classes.item}
              >
                <a
                  href={translation?.href}
                  className={classes.parentItem}
                >
                  {translation?.locale?.substr(0, 2)}
                </a>
              </li>
            )
          }) : languages
          .filter((lang) => !lang?.includes(activeLang))
          .map((lang, index) => {
            return (
              <li
                key={index.toString()} // eslint-disable-line react/no-array-index-key
                className={classes.item}
              >
                <a
                  href={`/${lang}`}
                  className={classes.parentItem}
                >
                  {lang}
                </a>
              </li>
            )
          })}
      </ul>
    </div>
  )
}

export default injectSheet(style)(LangNav)
