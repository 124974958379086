import { useSelector, shallowEqual } from 'react-redux'
import getRoutes from '@/router/routes'
import AnimatedRoutes from '@/router/TransitionSwitch/AnimatedRoutes'
import RouteTransition from '@/router/TransitionSwitch/RouteTransition'

function Router({ routes }) {
  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { content } = useSelector((state) => ({
    content: state.page,
  }), shallowEqual)

  const getImageUrl = (index, size = 'full', slug = null, type = 'pages') => {
    let page = null
    if (slug === null) {
      const { current, currentType } = content
      page = currentType === 'pages' ? content.pages : content.posts
      slug = current
    } else {
      page = type === 'pages' ? content.pages : content.posts
    }
    if (`${slug}` in page) {
      page = page[slug].media_post
      if ((page[index] !== undefined) && (page[index].sizes !== undefined)) {
        if (page[index].sizes[size] !== undefined) {
          return `${page[index].source_url}/${page[index].sizes[size].file}`
        }
        return `${page[index].source_url}/${page[index].sizes.full.file}`
      }
    }
    return ''
  }

  const getImageTitle = (index, slug = null, type = 'pages') => {
    let page = null
    if (slug === null) {
      const { current, currentType } = content
      page = currentType === 'pages' ? content.pages : content.posts
      slug = current
    } else {
      page = type === 'pages' ? content.pages : content.posts
    }
    if (`${slug}` in page) {
      page = page[slug].media_post
      if ((page[index] !== undefined) && (page[index].title !== undefined)) {
        return page[index].title
      }
    }
    return ''
  }
  return (
    <div className="route-container">
      <AnimatedRoutes
        initial={false}
      >
        {getRoutes(routes, getImageUrl, getImageTitle)
          .map(({ key, path, exact, component, render }) => {
            return path !== undefined || path?.length > 0 ? (
              <RouteTransition
                key={key}
                path={path}
                exact={exact}
                component={component}
                render={render}
              />
            ) : null
          })}
      </AnimatedRoutes>
    </div>
  )
}

export default Router
