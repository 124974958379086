/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import { useCallback, forwardRef, useEffect, useRef } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import gsap from 'gsap'
import injectSheet from 'react-jss'
import clsx from 'clsx'
import LangNav from '@/components/__new/LangNav'
import DelayLink from '@/components/DelayLink'
import actions from '@/actions'
import style from './style'

const Nav = forwardRef(({ classes }, ref) => {
  const $root = useRef()
  const $overlay = useRef()
  const $lang = useRef()
  const $nav = useRef()
  const q = gsap.utils.selector($root)

  const { compact, navOpen, mainMenu1, mainMenu2, mainMenu3, mainMenu4, mainMenu5, socialMenu, locales, translations } = useSelector((state) => ({
    mainMenu1: state.nav['mainMenu-1'] || [],
    mainMenu2: state.nav['mainMenu-2'] || [],
    mainMenu3: state.nav['mainMenu-3'] || [],
    mainMenu4: state.nav['mainMenu-4'] || [],
    mainMenu5: state.nav['mainMenu-5'] || [],
    socialMenu: state.nav.socialMenu || [],
    locales: state.locale.languages,
    translations: state.locale.translations,
    navOpen: state.nav.open,
    compact: state.nav.compact,
  }), shallowEqual)

  const dispatch = useDispatch()
  const setNavOpen = useCallback((index) => dispatch(actions.nav.setOpen(index)), [dispatch])

  /*------------------------------
  Close
  ------------------------------*/
  const handleClose = (e) => {
    if (e.type === 'keydown') {
      if (e.code === 'Escape') {
        setNavOpen(false)
      }
    } else {
      setNavOpen(false)
    }
  }

  useEffect(() => {
    if ($root.current) {
      gsap.set($root.current, { display: 'none' })
    }
  }, [])

  /*------------------------------
  Open Animation
  ------------------------------*/
  useEffect(() => {
    if ($root.current) {
      gsap.killTweensOf($root.current)
      gsap.set($root.current, {
        display: navOpen ? 'block' : 'none',
        delay: navOpen ? 0 : 1.2,
      })
    }
    if ($nav.current) {
      gsap.killTweensOf($nav.current)
      gsap.to($nav.current, {
        y: navOpen ? '0%' : '-100%',
        duration: 1.2,
        ease: 'power3.inOut',
      })
    }
    if (q('.navCol')) {
      gsap.killTweensOf(q('.navCol'))
      gsap.to(q('.navCol'), {
        y: navOpen ? '0' : '-50px',
        opacity: navOpen ? 1 : 0,
        stagger: 0.1,
        delay: 0.1,
        duration: 1.2,
        ease: 'power3.inOut',
      })
    }
    if ($overlay.current) {
      gsap.killTweensOf($overlay.current)
      gsap.to($overlay.current, {
        opacity: navOpen ? 1 : 0,
        duration: 1.2,
        ease: 'power3.inOut',
      })
    }
    if ($lang.current) {
      gsap.killTweensOf($lang.current)
      gsap.to($lang.current, {
        opacity: navOpen ? 1 : 0,
        duration: 0.5,
        delay: navOpen ? 1 : 0,
        ease: 'power3.out',
      })
    }

    if (navOpen) {
      window.addEventListener('keydown', handleClose)
    } else {
      window.removeEventListener('keydown', handleClose)
    }

    return () => {
      window.removeEventListener('keydown', handleClose)
    }
  }, [navOpen])

  /*------------------------------
  Render Nav
  ------------------------------*/
  const renderNav = (nav, index) => {
    return nav?.length > 0 ? (
      <div
        ref={ref}
        className={clsx({
          [classes.navCol]: true,
          [classes[`navCol${index}`]]: true,
          navCol: true,
        })}
      >
        {nav.map((item, ind) => {
          return (
            <div
              className={classes.navItem}
              key={`navCol${ind}`}
            >
              {item.url === '#' ? ( // eslint-disable-line no-nested-ternary
                <button className={classes.itemBig}>
                  {item.title}
                </button>
              ) : item.target === '_blank' ? (
                <a
                  className={classes.itemBig}
                  href={item.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.title}
                </a>
              ) : (
                <DelayLink
                  className={classes.itemBig}
                  to={item.url}
                  onMouseUp={() => setNavOpen(false)}
                >
                  {item.title}
                </DelayLink>
              )}
              {item.children && item.children?.length > 0 ? (
                <ul>
                  {item.children && item.children.map((child, i) => (
                    <li
                      key={`children${i}`}
                      className={classes.itemSmall}
                    >
                      {child.target === '_blank' ? (
                        <a
                          href={child.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {child.title}
                        </a>
                      ) : (
                        <DelayLink
                          to={child.url}
                          onMouseUp={() => setNavOpen(false)}
                        >
                          {child.title}
                        </DelayLink>
                      )}
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
          )
        })}
      </div>
    ) : null
  }

  return (
    <div
      ref={$root}
      className={clsx({
        [classes.root]: true,
        [classes.compact]: compact,
        newNav: true,
      })}
      data-lenis-prevent
    >
      <div
        ref={$overlay}
        className={classes.overlay}
        onClick={handleClose}
      />
      <div
        ref={$lang}
        className={classes.langNavDesktop}
      >
        <LangNav
          languages={locales}
          translations={translations}
        />
      </div>
      <div
        ref={$nav}
        className={classes.nav}
        data-lenis-prevent
      >
        {renderNav(mainMenu1, 1)}
        {renderNav(mainMenu2, 2)}
        {renderNav(mainMenu3, 3)}
        {renderNav(mainMenu4, 4)}

        <ul className={classes.langNavMobile}>
          {locales.map((obj, index) => {
            return (
              <li
                key={`key-${index}`}
                className={classes.item}
              >
                <a href={`/${obj}`} className={classes.parentItem}>{obj}</a>
              </li>
            )
          })}
        </ul>

        {renderNav(mainMenu5, 5)}
        {renderNav(socialMenu, 6)}
      </div>
    </div>
  )
})

export default injectSheet(style)(Nav)
